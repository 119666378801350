import React, { useEffect, useMemo, useState } from 'react';
import {
  makeStyles,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  CardActions,
  Card,
  Table,
  TableBody,
  TextField,
  Button,
  Checkbox,
  TablePagination
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteIcon from '@material-ui/icons/Delete';

import BlackListDeletePopup from './BlackListDeletePopup';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',

    paddingRight: '50px'
  }
}));

const BlackList = ({ blackList, deleteBlackList }) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [movePageNum, setMovePageNum] = useState(0);
  const [open, setOpen] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setPage(0);
    setMovePageNum(1);
  }, []);

  const columns = useMemo(() => {
    return Object.keys(blackList[0]);
  }, [blackList]);

  const rows = useMemo(() => {
    return blackList.reduce((result, cur) => {
      const row = [];
      for (let key in cur) {
        row.push(cur[key]);
      }
      result.push(row);
      return result;
    }, []);
  }, [blackList]);

  useEffect(() => {
    setPage(0);
  }, [rows, rows.length]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleDelete = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    if (event.target.checked) {
      setCheckedList([...checkedList, event.target.name]);
    } else {
      setCheckedList(checkedList.filter(id => id !== event.target.name));
    }
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((colItem, colIndex) => {
                    return (
                      <TableCell key={colIndex} align={'center'}>
                        <span>
                          <b>{colItem}</b>
                        </span>
                      </TableCell>
                    );
                  })}
                  <TableCell key={'checked'} align={'center'} width={100}>
                    <b>삭제여부</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 ? (
                  rows
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((rowsItem, rowsIndex) => {
                      return (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={rowsIndex}>
                          {rowsItem.map((valueItem, itemIndex) => {
                            return (
                              <TableCell key={itemIndex} align={'center'}>
                                {valueItem}
                              </TableCell>
                            );
                          })}
                          <TableCell align={'center'}>
                            <Checkbox
                              checked={
                                !!checkedList.find(id => id === rowsItem[0])
                              }
                              onChange={handleChange}
                              name={rowsItem[0]}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <div />
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <div>
          <TextField
            margin="dense"
            name="modePage"
            onChange={e => {
              setMovePageNum(e.target.value);
            }}
            value={movePageNum}
            variant="outlined"
          />
          <Button
            style={{
              marginLeft: 10
            }}
            onClick={() => {
              const pageNum = Number(movePageNum) - 1;
              if (pageNum >= 0 && pageNum * rowsPerPage < rows.length) {
                setPage(pageNum);
              }
            }}
            color="primary"
            variant="contained">
            Move page
          </Button>
          {rows.length > 0 && (
            <TablePagination
              component="div"
              count={rows.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[15, 20, 50, 100]}
            />
          )}
        </div>
        <Button
          disabled={!checkedList.length}
          onClick={() => handleDelete(checkedList)}
          variant="contained"
          color="primary">
          삭제
        </Button>
      </CardActions>
      <BlackListDeletePopup
        open={open}
        handleClose={handleClose}
        deleteBlackList={deleteBlackList}
        deleteIdList={checkedList}
      />
    </Card>
  );
};

export default BlackList;
